.head-page   {
    background: #fff;
    height: max-content;
    width: auto;
	margin: 0;
	padding: 20px;
    & .header {
        margin-left: 10px;
        margin-right: 10px;
        padding: 11px 10px 11px 10px;
        border: 1px solid #D0C9CE;
        border-top: 0px;
        background: #EEEAED repeat-x left bottom;
        height: auto;
    
        & H3 {
            text-transform: uppercase;
            font-family: Signika,Arial,Sans-Serif;
            font-weight: 300;
            font-size: 15px;
            color: #131516;
            line-height: 1.2em;
        }
    }        
    & a {
        color: #131516;
        text-decoration: none; 
    }
}

.headpage {
    text-align: center;
    padding-top: 0px;
    & .line {
        display: none;
    }
    & .show-grid {
        padding-top: 0px;
        &.row {
            margin-left: -8px;
            margin-right: -8px;
                    
        }
    }

}

.headpage .container-fluid {
	padding:unset;
}

.headpage-separator {
    margin-bottom: 0px;
    text-align: center;
}

.line-text {
    display: block;
    border-radius: 3px;
    font-size: 28px;
    text-transform: uppercase;
    margin-top: unset;
	background-color: transparent;
    font-family: Rajdhani-SemiBold;    
    color: #000;
}

.line {margin:0;}


.show-grid {
    padding-top: 0px;

}

.news-date {
    margin-top: 20px;
}

.show-grid .col-12, .show-grid .col-sm-12, .show-grid .col-md-12, .show-grid .col-lg-12, .show-grid .col-xl-12 {
    padding:0px;    
}

.show-grid .col-9, .show-grid .col-sm-6, .show-grid .col-md-6, .show-grid .col-lg-6, .show-grid .col-xl-4 {
    padding:0px;
    padding-bottom: 5px!important;
}

.show-news {
    padding:0px;
    padding-top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}


.review__band {
    width: 100%;
    font-size: 22px;
    font-family: "Rajdhani-SemiBold";
}

.review__album {
    width: 100%;
    font-size: 18px;
    font-family: "Rajdhani";
}

.review__points {
    font-size: 18px;
    font-family: "Rajdhani-SemiBold";
    color: #222;
}

.review__reviewer {
    font-size: 14px;
    font-family: "Rajdhani";
    position: absolute;
    bottom: 0.5em;
    left: 12;
    
    
}

.text {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 10% auto;
    align-content: center;
    padding: 5px 10px 10px 10px;
    border-radius: 10px;
    background: #888;
    color:#000;
}      

.headpage-news {
    width: 100%;
    margin-left: 20px;
    text-align: left;
    text-transform: none;    
    margin-bottom: 0px;

}

.headpage-news-header {
    font-size: 14px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    background: linear-gradient(90deg, #070000,#4c0001,#070000); 
    color: white;
    padding: 2px 10px 2px 10px;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    border-radius: .25rem;
    & .date-displayer {
        color: white;
        text-align: right;
        margin-top: -16px;
        margin-right: 0px;
    }
}
.headpage-news-header span{
    width: 70%;
}


.headpage-news-header:before {
    display: inline-block;
    width: 70%;
    border: 1px solid green;
    vertical-align: top;
}

.headpage-news-list {
    font-size: 12px;
    line-height: 1.2rem;
}