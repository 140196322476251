.page {
    background: #fff;
    max-width: 1340px;
    min-width: 360px;
    width: 100%;
    z-index: 5;
    &.opened {
        z-index:15;
        min-height:100vh;
    }
  
}

.content {
    display: flex;
    flex-direction: row;
    background: #fff;
    height: 100%;
    width: 100%;
    &.bottom {
        flex-direction: column;
        min-width: 360px;
    }

}

.empty-children {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    min-width: 300px;
}

      
.stage {
  background: #fff;
  width: 100%;
  position: relative;
}

.stage-searching {
    background: #fff;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
      
}

.stage-content {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ad-background {
    position: fixed;
    display:block;
    cursor: pointer;
    overflow: hidden;
    background-color: white;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 3840px;
    &.full {
        z-index: 1000;
    }
}

.ad-background-image {
    width:auto;
    height: auto;
}


.youtube-player {
    position:relative;
    height:0;
    padding-bottom:56.25%;
    & iframe {
        position:absolute;
        width:100%;
        height:100%;
        left:0; 
    }
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem;
    padding-bottom: 4px;
    margin-bottom: 18px;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 7px;
    text-transform: uppercase;
    font-size: 10px;
}

.breadcrumb a, .breadcrumb li {
	color: rgba(33,33,33,.8) !important;
}

