
.other-review-page {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
    & a {
        color: #131516;
        text-decoration: none;             
    }        
}

.other-review-detail {
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
}

.other-review-body {
    & .review-footer {
        padding-top: 4px;
        padding-bottom: 8px;
        font-size: 12px;
        font-family: Rajdhani;
    }
}

.other-review-detail-head {
    font-size: 20px;
    font-family: "Rajdhani-semibold";
    text-transform: uppercase;    
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    color: #fff!important;
    padding: 5px;
    margin-bottom: 5px;    
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .other-review-detail-head-info {
        display: flex;
        flex-direction: column;
    }
    & .other-review-name {
        font-size: 16px;
        font-family: "Rajdhani-semibold";
        text-transform: capitalize;        
    }
    & .company {
        color: white!important;
        text-transform: capitalize;
        font-size: 14px;
    }
    
    & .other-review-score {
        font-size: 24px;
        font-family: "Rajdhani-semibold";
        text-transform: uppercase;        
        float: right;
        margin-top: 8px;
        margin-left: 5px;
        margin-right: 10px;
    }
    
}    

.other-review-detail-right {
    float:right;
    & .score {
        font-size: 24px;
        font-family: "Rajdhani-semibold";
        text-transform: uppercase;
        margin-right: 0px;
        margin-bottom: 20px;
    }    
}

.other-review-detail-top {
    display:flex;
    /*height:250px;*/
    max-width:100%;
    width: 100%;
    & .other-review-detail-icons {
        margin-right: 0px;
        width: 100%;
    }
}

.other-review-detail-image {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    & img {
        width: auto;
        height: auto;
        max-width: 360px;
        max-height: 450px;
    }
}

.other-review-detail-body {
    max-width: 750px;
    margin: 0 auto;
    font-size: 14px;
    padding-top: 20px;

    & iframe {
        width: 100%;
    }  
    
}

.more-info-list {
    font-family: "Rajdhani-SemiBold";
    list-style-type: none;
    margin-left: -40px;    
}

.songlist-link {
    text-align: center;
    margin-top: 20px;
}