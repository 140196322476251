
.review-page {
    display: block;
    background: #fff;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    & a {
        color: #131516;
        text-decoration: none;
    }        
}

.review-detail {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;    
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
    & iframe {
        width: 100%;
        padding-bottom: 0px;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7); 
    }  
}
.review-body {
    & .review-footer {
        
        padding-top: 4px;
        padding-bottom: 8px;
        font-size: 12px;
        font-family: Rajdhani;
    }
}

.review-detail-head {
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Rajdhani-semibold";
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    /*background-color: #000;*/
    color: #fff!important;
    padding: 5px;
    margin-bottom: 5px;    
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .review-detail-head-info {
        display: flex;
        flex-direction: column;
    }

    & .review-name {
        font-size: 16px;
        font-family: "Rajdhani-semibold";
        text-transform: capitalize;      

    }
    & .review-score {
        font-size: 24px;
        font-family: "Rajdhani-semibold";
        text-transform: uppercase;        
        margin-top: 8px;
        margin-left: 5px;
        margin-right: 10px;
    }
    & .company a {
        color: white!important;
        text-transform: capitalize;
        font-size: 14px;
    }

}

.review-detail-top {
    display:flex;
    max-width:100%;
    width: 100%;
    margin-bottom: 20px;
    & .review-detail-icons {
        margin-right: 0px;
        width: 100%;
    }
}

 
.review-detail-image {
    width: 100%;
    text-align: center;

    & img {
        max-width: 450px;
    }
    & .image {
        width: 100%;
        height: auto;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7);
    }
    & .score {
        position: relative;
        margin-top: -60px;
        margin-right: -20px;
        float: right;
        & p {
            font-size: 24px;
            font-family: "Rajdhani-semibold";
            text-transform: uppercase;
            margin-right: 0px;
            margin-bottom: 20px;
        }
    }    

}

.review-detail-body {
    font-size: 14px;
    max-width: 700px;
    margin: 0 auto;
    & iframe {
        width: 100%;
        padding-bottom: 0px;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7); 
    }  
    & p  {
        max-width: 700px;
    }
    
}

.review-detail-songs {
    margin-top: 10px;
}


.song-list {
    font-family: "Rajdhani-SemiBold";
    list-style-type: none;
    margin-left: -40px;    
}

.songlist-link {
    text-align: center;
    margin-top: 20px;
}

.songlist-button {
    font-family: "Montserrat";
    background: linear-gradient(90deg,#070000,#4c0001,#070000);
    color: #fff;
    padding: 4px 20px!important;
    margin-left: 10px!important;
    margin-bottom: 5px!important;
    border-radius: .25rem!important;
    &.btn-link:hover {
        color: white!important;
    }    
    &.btn-link:active {
        background-image: linear-gradient(90deg,#070000,#4c0001,#070000)!important;
    }    
}

/*
@media screen and (max-width: 928px) {
    .old-releases {
        display: block;
        width: auto;
        height: 300px;
        background: #fff;
        margin-left: 15px;
        margin-right: 15px;
    }    
    
}
@media screen and (min-width: 928px) {
    .old-releases {
        display: block;
        width: auto;
        height: 300px;
        background: #fff;
        margin-left: 130px;
    }    
}
*/
.old-releases {
    display: block;
    width: auto;
    height: 300px;
    background: #fff;
}    


.release-picture {
    width: 140px;
    display: block;
    height: 140px;
}



.otherReviews {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    font-weight: bold;
}

.reactjs-coverflow_Coverflow {
    transform-style: preserve-3d;
    perspective: 500px;    
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    transform-style: preserve-3d;
    perspective: 500px;    
}
.reactjs-coverflow_Element {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
    cursor: pointer;
    backface-visibility: hidden;
    align-self: center;        
    transform: scale(0.8) !important;
    &.active {
        transform: scale(1.4)!important; 
    }    
}

.classic-div {
    position: inherit;
    & .classic-text {
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: 999;
        overflow: hidden;
        width: 75px;
        height: 75px;
     
        & span {
            font-size: 9px;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 100px;
            display: block;
            
            background: -webkit-linear-gradient(to right, #1F1C2C, #928DAB);
            background: linear-gradient(to right, #1F1C2C, #928DAB);
            position: absolute;
            top: 19px; 
            right: -21px;
        }
        & span::before {
            content: "";
            position: absolute; 
            left: 0px; 
            top: 100%;
            z-index: -1;
            border-left: 3px solid #333;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #333;
        }
        & span::after {
            content: "";
            position: absolute; 
            right: 0px; 
            top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid #333;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #333;
        }
    }
}


.sisalmys-review {
    display: block;
    /*max-width: 750px;*/
}
  