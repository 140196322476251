html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  min-width: 360px;
}

@font-face {
  font-family: Rajdhani;
  src: url("/style/fonts/Rajdhani-Regular.otf") format("opentype");
}

@font-face {
  font-family: Rajdhani-SemiBold;
  src: url("/style/fonts/Rajdhani-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: RobotoSlab;
  src: url("/style/fonts/RoboSlab-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  src: url("/style/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  src: url("/style/fonts/Montserrat-Regular.ttf") format("truetype");
}


body {
  font-style: normal !important;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.4!important;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
}

p {
  max-width: 750px !important;
  line-height: 1.5rem;
  font-weight: bolder;
}

a {
  color: black !important;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects !important;
}

h1 {
  font-size: 32px !important;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.author-link {
    font-size: 12px;
    font-family: "Rajdhani";
    padding-right: 10px;
}

.date-icon {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;
}

.writer-icon {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 4px;
}

.date-displayer {
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left:2px;
    font-size: 12px;
    font-family: Rajdhani;
}

.date-displayer .fa-calendar-o:before {
  content: "Julkaistu:";
  font-family: Rajdhani-semibold;
}

.iconReview {
  height: auto;
  width: 46px;
  padding-bottom: 8px;
}

.pop-player {
  width: 320px !important;
  max-width: 320px !important;
}


.card-body, .card-header {
  padding: 0 !important;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: 0;
}

@media screen and (min-width: 750px) {
	.card-header {
    margin: auto 0!important;
    width: 100%!important;
	}
}

/* buttons */
.btn {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: .375rem .75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;

  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow:none;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show.btn-secondary.dropdown-toggle, button.search-button.btn.btn-secondary {
	line-height: 37px;
  background:transparent;
}


.button.btn.btn-link {
  background: linear-gradient(90deg,#070000,#4c0001,#070000);
  color: #fff!important;
  padding: 4px 20px;
  margin-left: 10px;
  margin-bottom: 5px;
  border-radius: .25rem;
}

/* search button */
button.search-button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

}

.search-button {
  padding: 0px!important;  
  margin-left: 10px;

}

/* Fade in animation */
@keyframes fadeIn {
  from { 
    opacity: 0;
      -webkit-transform: translate3d(0, 3%, 0);
    transform: translate3d(0, 3%, 0);
  }

  to {
    opacity: 1;
       -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeIn-two {
  from { 
    opacity: 0;
      -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
       -webkit-transform: none;
    transform: none;
  }	
}

.fa-search:before {
  font-size: 24px;
  line-height: 37px !important;
}

/* card style */

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;
  border-radius: .25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: none !important;
}



/* breaking to smallest of mobiles */

@media screen and (max-width: 567px) {
	.show-grid .col-9, .show-grid .col-sm-6, .show-grid .col-md-6, .show-grid .col-lg-6, .show-grid .col-xl-4 {
    margin-left: auto;
    margin-right: auto;
	}
	.col-9 {
    -ms-flex: unset;
    flex: unset;
    max-width: 100%;
  }
}

@media screen and (min-width: 576px) {
	.show-grid .col-9, .show-grid .col-sm-6, .show-grid .col-md-6, .show-grid .col-lg-6, .show-grid .col-xl-4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
      max-width:unset !important;
    }
  }
  

.valkoinen {
  color: #ffffff;
}

.container {
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.year-calender {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.year-calender.daymonth {
  display: flex;
  flex-direction: column;
}


.year-calender-text {
  text-align: center;
  font-weight: bold;
}      

.year-calender-month {

  font-family: Rajdhani-semibold;    
  font-size: 10px;
  text-transform: capitalize;
}

.year-calender-year {
  font-weight: lighter;  
  font-size: 14px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  margin-top: 8px;
  margin-left: -5px;
}

.all-gigs-page {
  margin-left: 20px;
  margin-right: 20px;

}

.all-gigs {
  margin-left: 0px;
  margin-right: 0px;

}

/* today splitter */
.all-gigs .btn-secondary {
  color: rgba(33,33,33,1);
  background-color: #ffffff;
  border-bottom: 1px solid rgba(33,33,33,.075);
  border-left: 1px solid rgba(33,33,33,.31);
  margin-top: 0px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  margin-bottom: 0 !important;
}

.all-gigs .nav-link.active {
  border-color: unset;
  border-top: 1px solid rgba(33,33,33,.31)!important;
  border-left: 1px solid rgba(33,33,33,.31)!important;
  border-right: 1px solid rgba(33,33,33,.31)!important;
  border-bottom: 1px solid rgba(33,33,33,.31)!important;
  border-radius: 3!important;
  background-color: #2F0000!important;
  
}

.all-gigs .nav-header {
	background: rgba(33,33,33,1);
}

.all-gigs a.active.nav-link .tab-header {
	color: rgba(255,255,255,1);
}

.all-gigs .card {
    border-radius: 0;
    padding: 0.375rem 0.75rem;
    text-align: left;
}


.all-gigs .btn-secondary:focus {
	box-shadow:none;
    background-color: #d9dcdf; 
	color: black;
}

.all-gigs .latest a {
	color: rgba(33,33,33,1);
}

.all-gigs .nav-tabs .nav-link:hover {
	border: 1px solid transparent;
    cursor: pointer;
}

.all-gigs .icon-right {
    display: flex;
    flex-direction: column;
    
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: middle;
    white-space: normal;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    width: 100%;

}

.all-gigs-button {
  width: 100%;
  display: flex;
}

.button-center {
  justify-content: center;  
  width: 100%;
  margin-right:10px;
}


.all-releases {
  margin-left: 20px;
  margin-right: 20px;
}

/* today splitter */
.all-releases .btn-secondary {
  color: rgba(33,33,33,1);
  background-color: #ffffff;
  border-bottom: 1px solid rgba(33,33,33,.075);
  border-left: 1px solid rgba(33,33,33,.31);
  margin-top: 0px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0px;
  margin-bottom: 0 !important;
}

.all-releases .nav-link.active {
  border-color: unset;
  border-top: 1px solid rgba(33,33,33,.31)!important;
  border-left: 1px solid rgba(33,33,33,.31)!important;
  border-right: 1px solid rgba(33,33,33,.31)!important;
  border-bottom: 1px solid rgba(33,33,33,.31)!important;
  border-radius: 3!important;
  background-color: #2F0000!important;
  
}

.all-releases .nav-header {
	background: rgba(33,33,33,1);
}

.all-releases a.active.nav-link .tab-header {
	color: rgba(255,255,255,1);
}

.all-releases .card {
    border-radius: 0;
    padding: 0.375rem 0.75rem;
    text-align: left;
}


.all-releases .btn-secondary:focus {
	box-shadow:none;
    background-color: #d9dcdf; 
	color: black;
}

.all-releases .latest a {
	color: rgba(33,33,33,1);
}

.all-releases .nav-tabs .nav-link:hover {
	border: 1px solid transparent;
    cursor: pointer;
}

.all-releases .icon-right {
    display: flex;
    flex-direction: column;
    
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: middle;
    white-space: normal;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    width: 100%;

}

.all-releases-button {
  width: 100%;
  display: flex;
}