footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1340px;
  margin-top: 30px;
  background: #fff;
  border-top-color: darkgray;
  border-top-style: solid;

}

.footer-link-text {
    display: block;
    flex-direction: row;

    /*position: relative;*/
    text-align: center;
    padding-bottom: 10px;
    font-size: 12px;
    /*
    vertical-align: middle;
    text-transform: uppercase;
    */
}
