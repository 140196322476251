
.articles-page {
    display: block;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 280px;
    height: max-content;
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
    & a {
        color: #131516;
        text-decoration: none;
    }    
}

.articles-page-fixed {
    width: 100%;
    min-width: 100%;
}


.article-detail {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;

    & .my-card-header {
        padding: 5px;
    }
}
.article_body {
    & .article-footer {
        padding-top: 4px;
        padding-bottom: 8px;
        font-size: 12px;
        font-family: Rajdhani;
    }
}

.article-line-image {
    width: 100px;
    height: auto;
    max-width: 150px;
}

.articles-list {
    min-width: 350px;
}

.article-list-header {
    font-family: "Rajdhani-semibold";
    text-transform: uppercase;
    color: #107896;

}

.article-list-ingres {
    color: #131516;
    font-size: 16px;
    font-family: "Rajdhani-semibold";
}

.article-detail-head {
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    color: #fff!important;
    padding: 0.4rem 1rem;
    margin-bottom: 5px; 
    text-transform: uppercase;
    border-radius: 4px;

    & .article-name {
        width: 100%;
    }
    & .score {
        float: right;
        margin-right: 0px;
        padding-right: 10px;
        
    }
}


.article-detail-hero {
    margin:0;
    position: relative;
    overflow: hidden;
    z-index:1;
    border-radius: .25rem;
    margin-bottom: 20px;
    padding: 0px 0px;
    & .article-image {
        font-size: 14px;
        width: 100%;
        height: auto;
        max-width: 1340px;
        transform: skewY(-2.2deg);
        transform-origin:0 0;
        
        -webkit-backface-visibility: hidden;
    }
      
    .cover {
        position: absolute;
        bottom: 0px;
        right: 20px;
        width: 25%;
        
        height: auto;
        z-index: 4000;
    }

    .title1 {
        color: white;
        font-size: 44px;
        margin-bottom: 13px;
        position: relative;
        
        span {
          position: absolute;
          top: 3px;
          margin-left: 12px;
          background: #C4AF3D;
          border-radius: 5px;
          color: #544C21;
          font-size: 14px;
          padding: 0px 4px;
          
        }
    }
    
    .title2 {    
        position: absolute;
        color: #ffff;
        font-size: 20px;    
        font-weight: 300;
        margin-bottom: 15px;
        top: 0px;
        right: 0px;
        margin-left: 10px;
        background: rgba(0,0,0,.6);
        padding: 20px 30px;
    }          
}

.article-detail-body {
    font-size: 14px;
    max-width: 750px;
    margin: 0 auto;
    padding-top: 20px;
    & img {
        margin-top: 20px;
        margin-bottom: 20px;
        height: auto;
        max-width: 100%;
        border-radius: .25rem;
    }

    & iframe {
        width: 100%;
        padding-bottom: 0px;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7);        
    }

    & h2 {
        font-size: 24px;
        text-transform: uppercase;
    }
}


.camera-icon {
    margin-left: 10px;
    margin-right: 5px;
}
