.headlines {
    background: #fff;

    & .carousel-picture {
        display: flex;
        &.bottom {
            min-width:300px;
            flex-direction: column;
        }

        & .carousel-text {
            display: inherit;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
            padding-right: 25px;
            font-size: 28px;
            font-family: "Rajdhani-SemiBold";
            text-align: center;
            text-transform: uppercase;    
            line-height: 1.1;
            &.bottom {
                font-size: 18px;
            }        
        }
    }
}

.headlines-slider {
    display: flex;
    flex-direction: row;
    &.bottom {
        flex-direction: column;
        min-width: 300px;
    }
 }

.headlines-page {
    background: #fff;
    margin-left: 20px;
    margin-right: 20px;
}

.headlines-image {
    border-radius: 10px;
}

.click-button-right {
    display: block;
    position: absolute; 
    font-size: 15px;
    line-height: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translate(0,-50%);
    color: transparent;
    border: none;
    outline: 0;
    padding: 0; 
    background: #fff;
    color: #000;
    right: -20px;
}    

.click-button-left {
    display: block;
    position: absolute; 
    font-size: 15px;
    line-height: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translate(0,-50%);
    color: transparent;
    border: none;
    outline: 0;
    padding: 0; 
    background: #fff;
    color: #000;
    left: -20px;
}    

.news-fluid {
    max-height: 460px;
    margin-left: 10px;
    padding: 0px;  
    height: auto;
    &.bottom {
        margin-top: 20px;
        margin-left: 0px;
        height: 400px;
    }
}