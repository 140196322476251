
.pages-page {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;
    & a {
        color: #131516;
        text-decoration: none;
    }    
}

.pages-detail {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;

    & .my-card-header {
        padding: 5px;
    }

}

.page-line {
    width: 100px;
    height: auto;
    max-width: 150px;
    & h2 {
        font-size: 14px;
    }
    & h3 {
        font-size: 14px;        
    }
}

.pages-body {
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(71, 0, 1,.2);
    width:100%;
}

.pages-list {
    padding-top: 10px;
    & div {
        max-width: 750px;
    }
    & img {
        width: 0px;
        height: 0px;
    }
    & h2 {
        font-size: 18px;
    }
}

.pages-header {
    font-size: 18px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    color: #fff!important;
    padding: 4px;
    padding-left: 8px;
    margin-bottom: 5px;
    border-radius: .25rem;
    
}

.pages-list-header {
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    width:100%;
    color: #fff!important;
}

.pages-list-read-more {
    position: relative;
    display: inline-block;
    margin-left: 0px;
	margin-bottom: 20px;
    background: linear-gradient(90deg,#070000,#4c0001,#070000);
    color: #fff!important;
    padding: 4px 20px !important;
	border-radius: .25rem !important;
}

.page-detail-body {
    font-size: 14px;
    max-width: 750px;
    margin: 0 auto;
    & img {
        margin-top: 20px;
        margin-bottom: 20px;
        height: auto;
        max-width: 100%;
        border-radius: .25rem;
    }

    & iframe {
        width: 100%;
        padding-bottom: 0px;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7);        
    }

    & h2 {
        font-size: 24px;
        text-transform: uppercase;
    }
}

.page-detail-head {
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    color: #fff!important;
    padding: 0.4rem 1rem;
    margin-bottom: 5px; 
    text-transform: uppercase;
    border-radius: 4px;

    & .page-name {
        width: 100%;
    }
}
