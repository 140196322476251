.notification {
    position: fixed;
    bottom: -140px;
    width: 100%;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    background-color: #2ecc71;
    color: #fff;
    font-size: 1.4em;
    transition: bottom 0.5s ease;
    display: none;
    &.show {
      bottom: 0;
      display: flex;
    }
  }
  