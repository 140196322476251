.today-splitter {
    display: block;
    margin-right: 15px;
    margin-bottom: 15px; 
    width:30%;
    max-width: 300px;
    /*
    border: 2px solid transparent;
    border-radius: 4px;    
    border-color: #e7e7e7;
*/
    &.bottom {
        width: 100%;
        border: 0px;
        padding-right:20px;
        padding-left: 20px;
        text-align: center;
        max-width: 100%;
    }
}


/* today splitter */
.today-splitter .btn-secondary {
    color: rgba(33,33,33,1);
    background-color: #ffffff;
    border-bottom: 1px solid rgba(33,33,33,.075);
	border-left: 1px solid rgba(33,33,33,.31);
    margin-top: 0px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    margin-bottom: 0 !important;
}


.today-splitter .tab-content {
    /*border: 1px solid rgba(33,33,33,.31);*/
    /*box-shadow: 1px 3px 5px rgba(33,33,33,0.15);*/
}

.today-splitter .nav-link.active {
    border-top: 1px solid rgba(33,33,33,.31);
    border-left: 1px solid rgba(33,33,33,.31);
    border-right: 1px solid rgba(33,33,33,.31);
    border-bottom: 1px solid rgba(33,33,33,.31);
    border-radius: 3;
    background-color: #2F0000;

}

.today-splitter .nav-header {
	background: rgba(33,33,33,1);
}

.today-splitter a.active.nav-link .tab-header {
	color: rgba(255,255,255,1);
}

.today-splitter .card {
    border-radius: 0;
    padding: 0.375rem 0.75rem;
    text-align: left;
}


.today-splitter .btn-secondary:focus {
	box-shadow:none;
    background-color: #d9dcdf; 
	color: black;
}

.today-splitter .latest a {
	color: rgba(33,33,33,1);
}

.today-splitter .nav-tabs .nav-link:hover {
	border: 1px solid transparent;
    cursor: pointer;
}

.today-splitter .icon-right {
    display: flex;
    flex-direction: column;
    
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: middle;
    white-space: normal;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    width: 100%;

}


.gig-band {
    /*font-family: "Roboto";*/

    list-style-type: none;
    margin-left: -40px;
    font-size: 14px;
}

.gigs {
    width: auto;
    min-width: 250px;
}

.gigs span:nth-of-type(2) {
    display: block;
}

.gig-twitter {
    position: relative;
    vertical-align: top;
    float: right;
    font-size: 16px;
    padding-right: 20px;
    & i  {
        font-size: 24px;
    }
    .fa-twitter:before {
        content: "\f099";
        color: black;
    }
  
}


.releases {
    width: auto;
    min-width: 250px;
}

.latest {
    width: auto;
    min-width: 250px;
    text-align: left;

}

.latest-entry:hover {
    background-color: #d9dcdf; 

    border-color: #d9dcdf;
    & .latest-header {
        & a {
            text-decoration: None;
            color: black!important;
        }

    }
}

.latest-entry {
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    width: 100%;
    & .latest-header {
        display: flex;
        flex-direction: row;
        & a {
            text-decoration: None;
            color: black;
        }

    }
}
.latest-type .badge {
    font-size: 85%;
    border-radius: 6px;
}

.right-title {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;

}

.tab-header {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;
    color: rgba(0,0,0,1);
}


.fbevent-text {
    display: inline;
    font-size: 16px;
    padding-left: 8px;
    color: black;   
}
/*@media screen*/

.tab-pane {
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    margin-bottom: 20px;
    
    &.scroll {        
        height: 700px;
        padding-bottom: 5px;
    }
    &.mobile {
        height: 400px;
        padding-bottom: 5px;
    }
}


.gig-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.gig-header-text {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    text-align: left;
}

.calender {
    display: flex;
    flex-direction: column;
}


.calender-text {
    text-align: center;
    font-weight: bold;
}      

.calender-month {

    font-family: Rajdhani-semibold;    
    font-size: 10px;
    text-transform: capitalize;
}

.icon-down-arrow {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-content: flex-end;

}

.down-arrow {
    float: right;
}

.today-tab-fixed {
    position: fixed; 
    height: 10px; 
    top: 0px; 
    right: 0; 
    z-index: 3000;
    margin-top: 5px;
    margin-right: 80px;
    & .btn {
        margin-top: 5px;

    }
}

.today-button {
    text-transform: capitalize;    
    color: black!important;
    font-size: 1rem;
    display: inline-block !important;
    font-weight: 400 !important;
    text-align: center;
    white-space: nowrap !important;
    vertical-align: bottom !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    border: 1px solid transparent !important;
    padding: .375rem .75rem !important;
    line-height: 1 !important;
    border-radius: 0 !important;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out !important;    
    
}

.now-menu {
    width: 320px;
    margin-left: -250px;
    background-color: white;
    
}
.now-items {
    width: 320px;
    background-color: white;
    border: 1px solid black;
    
}
