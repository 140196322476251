.my-pagination {
    font-family: Montserrat;
    font-size: 18px;
    margin-top: 20px;
    margin-left: -40px;
    & ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
        border-style: solid;
        border-width: 1px;
        vertical-align: middle;
        margin-right: 8px; 

    }

    & li {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 4px;
        min-width: 48px;
        vertical-align: middle;
        text-align: center;
        list-style: none;
        font-family: Montserrat;
        outline: 0;
        opacity: .8 !important;
        border-radius: .25em;
        border: 0px !important;
        background: rgba(33,33,33,1)!important;        
        &.active {
            border-color: #000;
            font-weight: 600;
            background: -webkit-gradient(linear,left top,right top,from(#070000),color-stop(#4c0001),to(#070000)) !important;
            background: linear-gradient(90deg,#070000,#4c0001,#070000) !important;
            opacity: 1 !important;
            border: 0px !important;            
        }

        & a {
            color: rgba(255,255,255,1) !important;
            width: 100% !important;
            height: 100% !important;
            display: inline-block !important;            

        }
    }

}
