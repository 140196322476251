.search-result-line {
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 10px;
    & a {
      color: #131516;
      text-decoration: none;        
    }
}

.search-review-image {
  width:auto;
  height:100px;
  margin-right: 10px;
}

.search-article-image {
  width:auto;
  height:100px;
  margin-right: 10px;
}

.search-other-image {
  width:auto;
  height:100px;
  margin-right: 10px;
}

.search-tab {
  width: 100%;

  & .tab-content {
    width: 100%;
  }
}

.search-page {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 8px;
  min-width: 280px;
}

.media-header {
  font-size: 18px;
}

.searching {
  position: relative;
  top: -59px;
  left: 29px;
}

.search-other-left {
  width: 110px;
}

.search-gigs-left {
  width: 110px;
}

.search-releases-left {
  width: 110px;
}

.search-review-left {
  width: 130px;
}

.search-interview-left {
  max-width: 40%;
  margin-right: 10px;
  & img  {
    max-width: 150px;
    width: 100%;
    height: auto;
  }
}


.search-results {
  display: block;
  background: #fff;
  min-width: 280px;
  height: max-content;
  opacity: 0;
  -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
  animation: fadeIn .35s ease-in-out 0s forwards;   
   
}

.search-pagination {
  width: 100%;
}