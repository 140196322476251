.some-buttons {
    margin-left: 0px;
    margin-top: 0px;
    text-align: center;
}
  
.some-text {
    display: block;
    font-size: 28px;
    text-transform: uppercase;
    margin-top: unset;
    color: #000;
    font-family: Rajdhani-SemiBold;
}

.some-icons {
    justify-content: center;
}
  
.menu-some-icon {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    color: black;
    & a {
      color: #2a3132;
      text-decoration: none;
    }
  
    
    .fa-twitter:before {
      content: "\f099";
      color: black;
    }
}
  
  
.menu-comments {
    padding-top: 0px;
    & a {
      color: #000000 ! important;
    }
}