/* advertisement */

.kuva_5, .kuva_4, .kuva_3, .kuva_6 {
	padding-top: 30px;
/*    border-top: 1px solid rgba(33,33,33,.15);
    border-bottom: 1px solid rgba(33,33,33,.15);*/
	
}


.kuva_5 img, .kuva_4 img, .kuva_3 img, .kuva_2 img, .kuva_6 img {
	width: 100%;
	height: auto;
}


.kuva_7 {
    width: auto;
    min-width: 300px;
    height: max-content;
     margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;    
    display: flex;
    flex-direction:row;
    justify-content: center;
 }

.kuva_8, .kuva_9, .kuva_10, .kuva_11{
    width: auto;
    min-width: 300px;
    height: max-content;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;        
    text-align: center;
}


.kuva_12  {
    margin-top: 20px;
}

.kuva_13  {
    margin-top: 20px;
}

.kuva_14 {
    margin-top: 20px;
}

.kuva_16 {
    margin-top: 20px;
}
.kuva_18 {
    width: auto;
    min-width: 300px;
    height: max-content;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;        
    text-align: center;
}

.side-by-side {
    display: flex;
    flex-direction:row;
    justify-content: center;

}

.mainos {
    margin-top: 10px;
    text-align: center;
}

.paikka_2 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}