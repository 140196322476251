.admin-page {
    display: block;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;
    min-width: 280px;
    height: max-content;
    margin-left: 10px;
    margin-right: 10px;
}

.my-area {
    height: 400px;
}