
.news-page {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;    
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
    & a {
        color: #131516;
        text-decoration: none;
    }        
}

.news-detail {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    margin-left: 20px;
    margin-right: 20px;    
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;
    & .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0;
    }     
}

.news-body {
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(71, 0, 1,.2);

    & .news-header-image {
        width: 100%;
        height: auto;
        max-width: 750px;
    }
}

.news-list {
    padding-top: 10px;
    & div {
        max-width: 750px;
    }
    & img {
        width: 0px;
        height: 0px;
    }
}

.news-header {
    font-size: 18px;
    font-family: "Rajdhani-semibold";
    text-transform: none;  
    background: linear-gradient(90deg, #070000,#4c0001,#070000); 
    padding: 4px;
    padding-left: 8px;
    margin-bottom: 5px;    
    border-radius: .25rem;
}

.news-list-header {
    padding-left: 5px;
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    color: #fff!important;
}

.news-detail-head {
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;  
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    color: #fff!important;
    padding: 0.4rem 1rem;
    margin-bottom: 5px; 
    border-radius: 4px;
    text-transform: uppercase;
}

.news-detail-footer {
    font-size: 24px;
    font-family: Rajdhani;
}

.news-detail-body {
    font-size: 14px;
    max-width: 750px;
    margin: 0 auto;
    & img {
	    margin-top: 20px;
        margin-bottom: 20px;
    }

    & iframe {
        width: 100%;
        padding-bottom: 1%;
    }
    & .news-detailed-header-image {
        width: 100%;
        height: auto;
        & img {

        }
    }
}



.news-list-read-more {
    position: relative;
    display: inline-block;
    margin-left: 0px;
    margin-bottom: 20px;
    background: linear-gradient(90deg,#070000,#4c0001,#070000);
    color: white!important;
    padding: 4px 20px;
	border-radius: .25rem;
}    
