
.icon-headpage-score {
    position: absolute;;
	float:right;
    margin:-110px -10px 0 0;
    z-index: 99;
    bottom: 0;
    right: 0;
    padding-right: 25px;
    padding-bottom: 0px;
    & p {
        font-size:22px;
        font-family: Rajdhani-Semibold;
        font-weight: bolder;
        /*position:absolute;*/
    }
    
}

.publishing-info {
    padding: 0;
    & .author {
        margin-top: -24px;
        float: right;
    }
    & .photographer {
        float: right;
    }    
}


.share-buttons {
    position: absolute;
    width: 80px;
    text-align: center;
    & .icon.social {
        display: block;
        margin:0px 0px 0 0;
        cursor:pointer;
        background:transparent;
        color:#000;
        transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s; 	
    }
    & .share-heading {
        font-family: Rajdhani-SemiBold;
        margin-top: 20px;
    }
    & img {
        margin-bottom: 10px;
    }

    &.horizontal {
        /*border-top: 1px solid rgba(33,33,33,.15);*/
        /*margin: auto 0!important;*/
        position: relative;
        display: flex;
        flex-wrap: wrap;
        /*max-width: 750px !important;*/
        width: 100%;
        
        
        
        & .share-heading {
            display: none;
        }
        & .icon.social {
            display: inline-flex;
            margin:0px 0px 0 0;
            cursor:pointer;
            background:transparent;
            color:#000;
            padding-left: 0px;
            padding-right: 10px;
            transition: 0.5s;
            -moz-transition: 0.5s;
            -webkit-transition: 0.5s;
            -o-transition: 0.5s; 	
        } 
        & img {
            margin-right: 10px;
        }   
    }
}

.share-buttons .fa-4x {
    font-size: 46px;
}


.icon.social.fb i  {
    color: #3b5998;
}

.icon.social.tw i  {
    color: #00aced;
}

.icon.social.spotify span {
    color: #1db954;
}

.icon.social.home span {
    color: #4c0001;

}

.icon.social.facehome span {
    color: #4c0001;
}
.author-button {
    background-color: white!important;
    z-index: 100;
    border-color: white!important;
    & .btn {
        background-color: white!important;
        color: black!important;
        font-size: 12px!important;
        font-family: Rajdhani-semibold;
        padding: 0!important;
        border-style: none!important;
        border-width: 0px!important;
        box-shadow: unset!important;

    }
}

.author-list-item {
    background-color: white!important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0.1rem !important;
    padding-bottom: 0 !important;
    & .author-link {
        font-size: 12px!important;
        padding: 0!important;
        float: left;
        & .camera-icon {
            margin-left: 5px;
        }
        & .writer-icon {
            margin-left: 5px;
        }
    }

}

.author-menu {
    background-color: white!important;
    background-clip: padding-box!important;
    margin-left: -100px;
}
