.radios-page {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;
    & a {
        color: #131516;
        text-decoration: none;
             
    }        
}

.radios-list {
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.radio-entry {
    margin: 0px;
}

.radios-list iframe.radio-entry {
    height: auto;
}

.radios-page div.element-card.promoted img {
    -webkit-transform: skewY(-2.2deg);
        -ms-transform: skewY(-2.2deg);
        transform: skewY(-2.2deg);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
}

.radios-page div.element-card.show .element-card-description, .radios-page div.element-card.news .element-card-title {
    background: transparent !important;
    background-color: transparent !important;
}

.radios-page div.element-card.promoted {
    box-shadow: none !important;
}

.radios-page div.element-card.show {
    box-shadow: none !important;
}

.radios-list iframe.radio-entry {
    height: 100% !important;
    border: 0px;
}

.radios-page .element-card-static .promoted {
    margin-bottom: 60px;
}


iframe.radio-entry {
    height:auto !important;
}
