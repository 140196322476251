.demo-page {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    
}

.demo-review {
    display: block;
    background: #fff;
    width: auto;
    min-width: 280px;
    height: max-content;
    & iframe {
        width: 100%;
    }  
    & p  {
        max-width: 700px;
    }    
}

.demo-review-detail {
    display: block;
    background: #fff;
    min-width: 280px;
    height: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;    
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0;
    -webkit-animation: fadeIn .35s ease-in-out 0s forwards;
    animation: fadeIn .35s ease-in-out 0s forwards;    

    & iframe {
        width: 100%;
        padding-bottom: 0px;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.7); 
    }  
}

.demo-header {
    font-size: 20px;
    font-family: "Rajdhani-semibold";
    text-transform: uppercase;    
    background: linear-gradient(to right, #070000,#4c0001,#070000); 
    margin-bottom: 5px;    
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .demo-header-info {
        display: flex;
        flex-direction: column;  
        & .demo-name {
            color: white;
            font-size: 16px;
            font-family: "Rajdhani-semibold";
            text-transform: capitalize;        
        }
        & .performer-name {
            color: white;

        }
    }
    & .demo-score {
        font-size: 24px;
        font-family: "Rajdhani-semibold";
        text-transform: uppercase;        
        margin-top: 8px;
        margin-left: 5px;
        margin-right: 10px;
    }

}

.demo-header-list {
    font-size: 24px;
    font-family: "Rajdhani-semibold";
    text-transform: none;    
    background: linear-gradient(90deg, #070000,#4c0001,#070000);     
    margin-left: 0px;
    margin-bottom: 5px;    
    border-radius: .25rem;
    padding: 4px 20px;
    color: white;
    & .demo-name {
        
        font-size: 16px;
        font-family: "Rajdhani-semibold";
        text-transform: capitalize;        
    }
}

.demo-detailed-body {
    font-size: 14px;
    max-width: 750px;
    margin: 0 auto;
    padding-top: 20px;
    & iframe {
        width: 100%;
    }  
    & p  {
        max-width: 750px;
    }
    & div {
        max-width: 750px;
    }
    
}

.demo-body {
    margin-top: 0px;
	border-bottom: 1px solid rgba(71, 0, 1,.2);
    margin-bottom: 20px;
}


.demo-detailed-ad {
    margin-top: 15px;
}

.demo-list-read-more {
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg,#070000,#4c0001,#070000);
    color: #fff!important;
    padding: 4px 20px !important;
    margin-left: 0px;
    margin-bottom:20px;
	border-radius: .25rem !important;
}