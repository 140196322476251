.header {
  display: block;
  width: 100%;
  max-width: 1340px;
  background: white;
  height: 55px;

  & .logo-place {
    display: flex;
    line-height: unset;
    white-space: nowrap;
    padding: 0;
    font-size: 18px;
    font-family: Rajdhani-SemiBold;
    color: rgba(33,33,33,1) !important;
    text-transform: uppercase;
    margin: 0;
  }  
  & .logo {
    cursor: pointer;
    width: 100%;
    min-width: 141px;
    height: auto;
    & .logo-pic {
      display: block;
      max-width:273px;
      max-height:150px;
      height: auto;
    }
  }
  & .mr-auto {
    align-self: flex-end;
  }
  & nav {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;
    float: left;
    & .container {
      width:100%;
    }
    & ul {
      & li {
      
        display: block;
        cursor: pointer;
        &:hover { text-decoration: underline; }
        & a {
          padding-left:  10px;
          padding-right: 10px;
          padding-bottom: 10px;
          display: inline-block;
          font-size: 18px;
          font-family: "Rajdhani-SemiBold";
          text-decoration: none;
          color: #121415;

        }
      }
    }
  }
}

.header nav ul li a {
	padding:0;
  font-size: 18px;
  font-family: Rajdhani-SemiBold;
  color: rgba(33,33,33,1) !important;
  text-transform: uppercase;
	width: auto;
  display: block;
	padding-left: 20px;
}

.nav-header {
	padding:0;
  font-size: 18px;
  font-family: Rajdhani-SemiBold;
  color: #6c757d;
  text-transform: uppercase;
	width: auto;
  display: block;
  
}

.dropdown-item:hover {

  text-decoration:underline!important;
}

.logo-wrapper ul.nav .nav-link {
	display:inline-block;
}


.header-top {
  display: block;
  position: fixed;
  z-index: 1039;  
  top: 0;
  height: 55px;
  width: 100%;
  max-width: 1340px;  
  background: white; 

  & .logo-place {
    display: flex;
    line-height: unset;
    white-space: nowrap;
    padding: 0;
    font-size: 18px;
    font-family: Rajdhani-SemiBold;
    color: rgba(33,33,33,1) !important;
    text-transform: uppercase;
    margin: 0;
  }  
  & .logo {
    cursor: pointer;
    width: 100%;
    height: 42px;
    & .logo-pic {
      display: block;
      width: 100%;
      max-width:273px;
      max-height:150px;
      height: auto;
    }
  }
  & nav {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;
    float: left;
    background: white; 
    z-index: 2000;

    
    & .collapsing {
      background: white;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right:  0px;
      }

    & ul {
      & li {
        display: block;
        cursor: pointer;
        &:hover { text-decoration: underline; }
        & a {
          padding:0;
          padding-left:  20px;
          display: inline-block;
          font-size: 18px;
          font-family: "Rajdhani-SemiBold";
          text-transform: uppercase;

          color: rgba(33,33,33,1) !important;

        }
      }
    }
  }  
}

.menu-start {
  vertical-align: bottom;
}

.search {
  padding-bottom: 10px;
}

.search-group {
  max-width: 300px;
  width:260px;
  margin: 0;
}

.form-inline {
  width:100%;
  padding: 0px!important;
  margin: 0px!important;
}

.menu-search {
  text-align: center;
  float: right;
  padding: 0;
  margin: 0;
}


.nav-link {
  padding-left: 5px;
  padding-right: 5px;
  color: #2a3132;
}

/* navigation */

.navbar button[type=submit] {
	background:transparent;
	padding:0;
}

.navbar button[type=submit]:hover {
	border:none;
	padding:none;
}

.navbar .fa-search:before {
  content: "\f002";
  color: rgba(33,33,33,.31);
}

.navbar .fa-search:hover:before {
  color: rgba(33,33,33,1);
}

.navbar-light .navbar-nav .nav-link {
  color: unset;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 20px;
      padding-left: 20px;
      text-transform: uppercase;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
      /*transform: translate3d(0px, 27px, 0px);
      top: 0px;
      left: 0px;
      will-change: transform;*/
      transform: unset !important;
      top: unset !important;
      left: unset !important;
      will-change: unset !important;
      opacity: 0;
      animation: fadeIn-two .20s ease-in-out 0s forwards;
      width: 170px;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: rgba(33,33,33,1);
  text-align: left;
  list-style: none;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,239,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(239,239,239,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(239,239,239,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efefef',GradientType=0 );
  background-clip: padding-box;
  border:0;
  border-bottom: 1px solid rgba(33,33,33,.15);
  /*border-left: 1px solid rgba(33,33,33,.15);
  border-right: 1px solid rgba(33,33,33,.15);*/
  border-radius: 0;
}

.dropdown-item.active, .dropdown-item:active {
	background: -webkit-gradient(linear,left top,right top,from(#070000),color-stop(#4c0001),to(#070000)) !important;
  background: linear-gradient(90deg,#070000,#4c0001,#070000) !important;
	color: rgba(255,255,255,1) !important;
}

.dropdown-menu.show {
  display: block;
  width: 100%;
}

.header .navbar {
  background-color: white;
  padding-bottom: 4px;
}

.main-menu {
  align-items: flex-end;
  width: 100%;
}

.burger-menu {
  background-color: white;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

}


.header .form-control {
  border: none;
  margin-right: 4px;
  border-radius: 0px;
  border: 1px solid transparent;
}

.header-top nav input#searchString, 
.header input#searchString {
  border: 1px solid rgba(33,33,33,.15);
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.header-top nav input#searchString:focus, .header input#searchString:focus {
border-bottom: 1px solid rgba(33,33,33,.5);
}

.header-top nav, .header-top nav .collapsing {
background-color: rgba(255,255,255,1) !important;
box-shadow: 0px 5px 1px -5px rgba(33,33,33,.3) !important;
}

.header .form-control:focus {
	-webkit-box-shadow: inset 0px 0px 15px 6px rgba(0,0,0,0.18);
    -moz-box-shadow: inset 0px 0px 15px 6px rgba(0,0,0,0.18);
    box-shadow: inset 0px 0px 10px 1px rgba(0,0,0,0.18);
	border-bottom: 1px solid rgba(33,33,33,.31);
    margin-right: 4px;
    border-radius: 0px;
	/* new shadow */
	-webkit-box-shadow: 0px 20px 34px -21px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 20px 34px -21px rgba(0,0,0,0.75);
	box-shadow: 0px 20px 34px -21px rgba(0,0,0,0.75);
}

.header-burger {
  width: 100%;
  max-width: 1340px;
  background: white;
  & .logo {
    cursor: pointer;
    width: 100%;
    min-width: 141px;
    height: auto;
    & .logo-pic {
      display: block;
      max-width:273px;
      max-height:150px;
      height: auto;
      width: 100%;
    }
  }

  & .logo-place {
    display: flex;
    line-height: unset;
    white-space: nowrap;
    padding: 0;
    font-size: 18px;
    font-family: Rajdhani-SemiBold;
    color: rgba(33,33,33,1) !important;
    text-transform: uppercase;
    margin: 0;
    width: 60%;
  }  
  & .mr-auto {
    align-self: flex-end;
  }
  & nav {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;
    float: left;
    & .container {
      width:100%;
    }
    & ul {
      & li {
      
        display: block;
        cursor: pointer;
        &:hover { text-decoration: underline; }
        & a {
          padding-left:  10px;
          padding-right: 10px;
          padding-bottom: 10px;
          display: inline-block;
          font-size: 18px;
          font-family: "Rajdhani-SemiBold";
          text-decoration: none;
          color: #121415;

        }
      }
    }
  }  
  
}

.header-burger-top {
  display: block;
  position: fixed;
  z-index: 1039;  
  top: 0;
  width: 100%;
  max-width: 1340px;  
  background: white; 
  
  & .logo-place {
    display: flex;
    line-height: unset;
    white-space: nowrap;
    padding: 0;
    font-size: 18px;
    font-family: Rajdhani-SemiBold;
    color: rgba(33,33,33,1) !important;
    text-transform: uppercase;
    margin: 0;
    width: 60%;
  }
  & .logo {
    cursor: pointer;
    width: 100%;
    height: 42px;
    & .logo-pic {
      display: block;
      width: 100%;
      max-width:273px;
      max-height:150px;
      height: auto;
    }
  }
  & nav {
    font-family: "Rajdhani-SemiBold";
    text-transform: uppercase;
    float: left;
    background: white; 
    z-index: 2000;

    
    & .collapsing {
      background: white;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right:  0px;
      padding-bottom: 10px;
      }

    & ul {
      & li {
        display: block;
        cursor: pointer;
        &:hover { text-decoration: underline; }
        & a {
          padding:0;
          padding-left:  20px;
          display: inline-block;
          font-size: 18px;
          font-family: "Rajdhani-SemiBold";
          text-transform: uppercase;

          color: rgba(33,33,33,1) !important;

        }
      }
    }
  }   
}