.main-page {
    display: block;
    background: #fff;
    width: 100%;
    min-width: 300px;
    height: max-content;
    margin-left: 0px;
    margin-right: 0px;    
}

